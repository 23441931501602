#root {
  overflow:  scroll;
  /* overflow: auto; */
}


body {
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  background-color: #fbfbfb !important;
  overscroll-behavior-y: contain !important;
  font-family: Poppins, Helvetica, sans-serif !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  user-select: none !important;
}


 .material-symbols-rounded {
   font-variation-settings:
           'FILL' 0,
           'wght' 400,
           'GRAD' 0,
           'opsz' 48
 }

/* Scrollbar */
:root::-webkit-scrollbar {
  width: 5px !important;
  background-color: transparent !important;
}

:root::-webkit-scrollbar-thumb {
  background-color: #fff200 !important;
}

:root::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.modal-fullscreen .modal-body::-webkit-scrollbar {
  width: 5px !important;
  background-color: transparent !important;
}

.modal-fullscreen .modal-body::-webkit-scrollbar-thumb {
  background-color: #fff200 !important;
}

.select2-results__options::-webkit-scrollbar {
  width: 5px !important;
  background-color: transparent !important;
}

.select2-results__options::-webkit-scrollbar-thumb {
  background-color: #fff200 !important;
}

.card-scroll::-webkit-scrollbar {
  width: 5px !important;
  background-color: transparent !important;
}

.card-scroll::-webkit-scrollbar-thumb {
  background-color: #fff200 !important;
}

/* Datepicker */
.flatpickr-day.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day.nextMonthDay:focus,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.today.inRange,
.flatpickr-day:focus,
.flatpickr-day:hover {
  background: #fff200 !important;
  color: #000000 !important;
}

.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
  background: #FFF200 !important;
  color: #000 !important;
  border-color: transparent !important;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed !important;
  color: #f1416ca6 !important;
  background: 0 0 !important;
}

/* TinySlider */
.tns .tns-nav {
  padding-bottom: 2ex !important;
  padding-top: 0 !important;
}

.tns .tns-nav button.tns-nav-active {
  background-color: #FFF200 !important;
}

.tns-outer {
  margin: 0 !important;
}

/* Select2 */
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover {
  background-color: #000000 !important;
}

.select2-container .select2-search--inline .select2-search__field {
  font-family: inherit !important;
}

.select2-results__option--highlighted {
  color: #5e6278 !important;
  background-color: #ffffff !important;
}

.select2-results__option--selected {
  color: #000000 !important;
  background-color: #FFF200 !important;
}

.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--selected:after {
  background-color: #000000 !important;
}

/* Form Control */
.dropdown.show>.form-control.form-control-solid,
.form-control.form-control-solid.active,
.form-control.form-control-solid.focus,
.form-control.form-control-solid:active,
.form-control.form-control-solid:focus {
  background-color: #f5f8fa !important;
  border-color: #f5f8fa;
  color: #5E6278 !important;
  transition: color .2s ease
}

.dropdown.show>.form-select.form-select-solid,
.form-select.form-select-solid.active,
.form-select.form-select-solid.focus,
.form-select.form-select-solid:active,
.form-select.form-select-solid:focus {
  background-color: #f5f8fa !important;
  border-color: #f5f8fa;
  color: #5E6278 !important;
  transition: color .2s ease;
}

.form-check.form-check-solid .form-check-input:checked {
  background-color: #fff200 !important;
  border-color: #fff200 !important;
}

/* Sweet Alert */
.swal2-validation-message::before {
  display: none !important;
}

/* Buttons */
.btn.btn-bso {
  color: #000 !important;
  border-color: #fff200 !important;
  background-color: #fff200 !important;
}

.btn.btn-bso .svg-icon,
.btn.btn-bso i {
  color: #000 !important;
}

.btn.btn-bso.dropdown-toggle:after {
  color: #000 !important;
}

.btn-check:active+.btn.btn-bso,
.btn-check:checked+.btn.btn-bso,
.btn.btn-bso.active,
.btn.btn-bso.show,
.btn.btn-bso:active:not(.btn-active),
.btn.btn-bso:focus:not(.btn-active),
.btn.btn-bso:hover:not(.btn-active),
.show>.btn.btn-bso {
  color: #000 !important;
  border-color: #fff200 !important;
  background-color: #fff200 !important;
}

.btn-check:active+.btn.btn-bso .svg-icon,
.btn-check:active+.btn.btn-bso i,
.btn-check:checked+.btn.btn-bso .svg-icon,
.btn-check:checked+.btn.btn-bso i,
.btn.btn-bso.active .svg-icon,
.btn.btn-bso.active i,
.btn.btn-bso.show .svg-icon,
.btn.btn-bso.show i,
.btn.btn-bso:active:not(.btn-active) .svg-icon,
.btn.btn-bso:active:not(.btn-active) i,
.btn.btn-bso:focus:not(.btn-active) .svg-icon,
.btn.btn-bso:focus:not(.btn-active) i,
.btn.btn-bso:hover:not(.btn-active) .svg-icon,
.btn.btn-bso:hover:not(.btn-active) i,
.show>.btn.btn-bso .svg-icon,
.show>.btn.btn-bso i {
  color: #000 !important;
}

.btn-check:active+.btn.btn-bso.dropdown-toggle:after,
.btn-check:checked+.btn.btn-bso.dropdown-toggle:after,
.btn.btn-bso.active.dropdown-toggle:after,
.btn.btn-bso.show.dropdown-toggle:after,
.btn.btn-bso:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-bso:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-bso:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-bso.dropdown-toggle:after {
  color: #000 !important;
}

.btn-check:active+.btn.btn-active-color-bso,
.btn-check:checked+.btn.btn-active-color-bso,
.btn.btn-active-color-bso.active,
.btn.btn-active-color-bso.show,
.btn.btn-active-color-bso:active:not(.btn-active),
.btn.btn-active-color-bso:focus:not(.btn-active),
.btn.btn-active-color-bso:hover:not(.btn-active),
.show>.btn.btn-active-color-bso {
  color: #fff200 !important;
}

.btn-check:active+.btn.btn-active-color-bso .svg-icon,
.btn-check:active+.btn.btn-active-color-bso i,
.btn-check:checked+.btn.btn-active-color-bso .svg-icon,
.btn-check:checked+.btn.btn-active-color-bso i,
.btn.btn-active-color-bso.active .svg-icon,
.btn.btn-active-color-bso.active i,
.btn.btn-active-color-bso.show .svg-icon,
.btn.btn-active-color-bso.show i,
.btn.btn-active-color-bso:active:not(.btn-active) .svg-icon,
.btn.btn-active-color-bso:active:not(.btn-active) i,
.btn.btn-active-color-bso:focus:not(.btn-active) .svg-icon,
.btn.btn-active-color-bso:focus:not(.btn-active) i,
.btn.btn-active-color-bso:hover:not(.btn-active) .svg-icon,
.btn.btn-active-color-bso:hover:not(.btn-active) i,
.show>.btn.btn-active-color-bso .svg-icon,
.show>.btn.btn-active-color-bso i {
  color: #fff200 !important;
}

.btn-check:active+.btn.btn-active-color-bso.dropdown-toggle:after,
.btn-check:checked+.btn.btn-active-color-bso.dropdown-toggle:after,
.btn.btn-active-color-bso.active.dropdown-toggle:after,
.btn.btn-active-color-bso.show.dropdown-toggle:after,
.btn.btn-active-color-bso:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-color-bso:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-color-bso:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-active-color-bso.dropdown-toggle:after {
  color: #fff200 !important;
}

.btn.btn-light {
  color: #7E8299 !important;
  border-color: #f5f8fa !important;
  background-color: #f5f8fa !important;
}

.btn.btn-light .svg-icon,
.btn.btn-light i {
  color: #7E8299 !important;
}

.btn.btn-light.dropdown-toggle:after {
  color: #7E8299 !important;
}

.btn-check:active+.btn.btn-light,
.btn-check:checked+.btn.btn-light,
.btn.btn-light.active,
.btn.btn-light.show,
.btn.btn-light:active:not(.btn-active),
.btn.btn-light:focus:not(.btn-active),
.btn.btn-light:hover:not(.btn-active),
.show>.btn.btn-light {
  color: #7E8299 !important;
  border-color: #f5f8fa !important;
  background-color: #f5f8fa !important;
}

.btn-check:active+.btn.btn-light .svg-icon,
.btn-check:active+.btn.btn-light i,
.btn-check:checked+.btn.btn-light .svg-icon,
.btn-check:checked+.btn.btn-light i,
.btn.btn-light.active .svg-icon,
.btn.btn-light.active i,
.btn.btn-light.show .svg-icon,
.btn.btn-light.show i,
.btn.btn-light:active:not(.btn-active) .svg-icon,
.btn.btn-light:active:not(.btn-active) i,
.btn.btn-light:focus:not(.btn-active) .svg-icon,
.btn.btn-light:focus:not(.btn-active) i,
.btn.btn-light:hover:not(.btn-active) .svg-icon,
.btn.btn-light:hover:not(.btn-active) i,
.show>.btn.btn-light .svg-icon,
.show>.btn.btn-light i {
  color: #7E8299 !important;
}

.btn-check:active+.btn.btn-light.dropdown-toggle:after,
.btn-check:checked+.btn.btn-light.dropdown-toggle:after,
.btn.btn-light.active.dropdown-toggle:after,
.btn.btn-light.show.dropdown-toggle:after,
.btn.btn-light:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-light:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-light:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-light.dropdown-toggle:after {
  color: #7E8299 !important;
}

/* noUI Slider */
.noUi-target .noUi-connect {
  background: #FFF200 !important;
}

/* Custom Class */
.text-hover-warning:hover {
  color: #fff200 !important;
}

.button-text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

.border-bso {
  border-color: #FFF200 !important;
}

.bg-bso {
  background-color: #fff200 !important;
}

.bg-bso-semi-back {
  background-color: #fff200B3 !important;
}

.bg-active-bso.active {
  background-color: #FFF200 !important;
}

.badge-light-bso {
  color: #000 !important;
  background-color: #fff200 !important;
}

.text-dots {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.text-dots-app {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
}

.btn-check:active+.btn.btn-active-bso,
.btn-check:checked+.btn.btn-active-bso,
.btn.btn-active-bso.active,
.btn.btn-active-bso.show,
.btn.btn-active-bso:active:not(.btn-active),
.btn.btn-active-bso:focus:not(.btn-active),
.btn.btn-active-bso:hover:not(.btn-active),
.show>.btn.btn-active-bso {
  color: #000 !important;
  border-color: #FFF200 !important;
  background-color: #FFF200 !important;
}

.btn-check:active+.btn.btn-active-bso .svg-icon,
.btn-check:active+.btn.btn-active-bso i,
.btn-check:checked+.btn.btn-active-bso .svg-icon,
.btn-check:checked+.btn.btn-active-bso i,
.btn.btn-active-bso.active .svg-icon,
.btn.btn-active-bso.active i,
.btn.btn-active-bso.show .svg-icon,
.btn.btn-active-bso.show i,
.btn.btn-active-bso:active:not(.btn-active) .svg-icon,
.btn.btn-active-bso:active:not(.btn-active) i,
.btn.btn-active-bso:focus:not(.btn-active) .svg-icon,
.btn.btn-active-bso:focus:not(.btn-active) i,
.btn.btn-active-bso:hover:not(.btn-active) .svg-icon,
.btn.btn-active-bso:hover:not(.btn-active) i,
.show>.btn.btn-active-bso .svg-icon,
.show>.btn.btn-active-bso i {
  color: #000 !important;
}

.btn-check:active+.btn.btn-active-bso.dropdown-toggle:after,
.btn-check:checked+.btn.btn-active-bso.dropdown-toggle:after,
.btn.btn-active-bso.active.dropdown-toggle:after,
.btn.btn-active-bso.show.dropdown-toggle:after,
.btn.btn-active-bso:active:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-bso:focus:not(.btn-active).dropdown-toggle:after,
.btn.btn-active-bso:hover:not(.btn-active).dropdown-toggle:after,
.show>.btn.btn-active-bso.dropdown-toggle:after {
  color: #000 !important;
}

.border-bso {
  border: 1px solid #FFF200 !important;
}

.border-bso2px {
  border: 2px solid #FFF200 !important;
}

.badge-bso {
  color: #000 !important;
  background-color: transparent !important;
}

.badge-bso-active {
  color: #000 !important;
  background-color: #FFF200 !important;
}

.custom-modal .ant-modal-content {
  padding-left: 0;
  padding-right: 0;
  height: calc(100vh - 10vh);

}

/* .ant-drawer .ant-drawer-body {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 10px ;
  margin-bottom: 10px;
} */

/* .ant-drawer-content-wrapper{
  height: 360px;
} */


@keyframes fade-out {
  0% {
    opacity: 1;
  }
}

.slides-manual::-webkit-scrollbar {
    display: none;
}

.custom-badge {
    font-size: 0.8rem;
    padding: 0.25rem 0.5rem; 
    border-radius: 50%; 
    line-height: 1; 
    width: 15px;
    height: 15px;
}


.scroll::-webkit-scrollbar {
  display: none; /* Chrome */
}


.scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

:root::-webkit-scrollbar {
  display: none;
}

:root::-webkit-scrollbar-thumb {
  display: none;
}

:root::-webkit-scrollbar-track {
  display: none;
}

*::-webkit-scrollbar {
  display: none;
}

*::-webkit-scrollbar-thumb {
  display: none;
}

*::-webkit-scrollbar-track {
  display: none;
}

.bg-none {
  background: none !important;
}


.full-screen {
  position: absolute !important;
  left: 0px !important;
  top: auto !important;
  bottom: 0px !important;
  width: 100vw !important;
}
.ant-picker-dropdown.full-screen-time-picker-range .ant-picker-header-next-btn,
.ant-picker-dropdown.full-screen-time-picker-range .ant-picker-header-super-next-btn {
    visibility: visible !important;
}
.full-screen-time-picker{
  position: absolute !important;
  left: 0px !important;
  top: auto !important;
  bottom: 0px !important;
  width: 100vw !important;
}

.full-screen-time-picker .ant-picker-datetime-panel .ant-picker-date-panel {
  width: 70vw !important;
}

.full-screen-time-picker .ant-picker-body {
  padding: 0.5rem !important;
}

 .full-screen-time-picker-range{
  position: absolute !important;
  left: 0px !important;
  top: auto !important;
  bottom: 0 !important;
  width: 100vw !important;
 }

.full-screen-time-picker-range .ant-picker-date-panel {
  width: 100vw !important;
}
.full-screen-time-picker-range .ant-picker-header {
  width: 100vw !important;

  
}

.full-screen-time-picker-range .ant-picker-panel-container {
  width: 100vw !important;
}
.full-screen-time-picker-range .ant-picker-body {
  width: 100vw !important;
}
.full-screen-time-picker-range .ant-picker-content {
  width: 100% !important;
}

.full-screen .ant-picker-panel-container {
  width: 100vw !important;
}

.full-screen .ant-picker-date-panel {
  width: 100vw !important;
}
.full-screen .ant-picker-header {
  width: 100vw !important;
}
.full-screen .ant-picker-body {
  width: 100vw !important;
}
.full-screen .ant-picker-content {
  width: 100% !important;
}

.ant-picker-footer-extra{
  height: 7vh !important;
}

.drp-style {
  position: fixed !important;
  left: 0px !important;
  top: auto !important;
  bottom: 0px !important;
  /* bottom: env(keyboard-inset-height, 0)!important; */
  width: 100vw !important;
  height: 41vh !important;
  padding-bottom: 30px !important;
  overflow: auto;
}

.drp-style .rc-virtual-list-holder{
  padding-top: 10px !important;
  padding-bottom: 40px !important;
  /* height: 41vh !important; */
}
.drp-style .rc-virtual-list{
  position: relative !important;
  height: 100% !important;
}

.drp-style .ant-select-item-option-content{
  font-weight: lighter !important;
  font-size: larger !important;
  padding-left: 12px;
  padding: 5px !important;
  /* border-bottom: dashed 1px #bfc0c7 ; */
}



/* Бегущая строка */
@keyframes marquee-scroll {
  0% {
      transform: translate(0, 0);
  }
  100% {
      transform: translate(-100%, 0)
  }
}

.marquee {
  display: block !important;
  width: 100% !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.marquee span {
  display: inline-block !important;
  padding-left: 100% !important;
  -webkit-animation: marquee-scroll 10s infinite linear !important;
}

.progress-bar {
  height: 3px;
  width: calc(100vh - 20px);;
  background-color: #86512c;
  position: absolute;
  top: 10;
  left: 10;
  transition: width 1s linear;
}

.story-content {
  position: relative;
  z-index: 1;
}

.ant-modal-content{
   /* width: 100vw !important;  */
  padding: 0px !important;

}

.ant-modal{
  max-width: 100vw !important;
  margin: 0px !important;
}


.btn.btn-telegram {
  color: #fff !important;
  border-color: #2aabee !important;
  background-color: #2aabee !important;
}
.btn-check:active + .btn.btn-telegram,
.btn-check:checked + .btn.btn-telegram,
.btn.btn-telegram.active,
.btn.btn-telegram.show,
.btn.btn-telegram:active:not(.btn-active),
.btn.btn-telegram:focus:not(.btn-active),
.btn.btn-telegram:hover:not(.btn-active),
.show > .btn.btn-telegram {
  color: #fff !important;
  border-color: #229ed9 !important;
  background-color: #229ed9 !important;
}
.btn.btn-telegram .svg-icon,
.btn.btn-telegram i {
  color: #fff !important;
}



.book-viewing-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000 !important;
  border: 1px solid #000000;
  border-color: #000000 !important;
  background-color: #ffffff !important;
  padding: 2px 3px !important; 
  height: auto; 
  text-align: center; 
  border-radius: 5px; 
}


.ant-picker-dropdown .ant-picker-ok .ant-btn{

  width: 100px !important;
  color: #000 !important;
  border-color: #FFF200 !important;
  height: 30px;
  background-color: #FFF200 !important;
  margin-bottom: 10px;
  margin-right: 10px;
}
.ant-picker-dropdown .ant-picker-datetime-panel .ant-picker-time-panel {

  width: 32% !important;
}


.ant-picker-dropdown:not(.full-screen-time-picker) .ant-picker-datetime-panel .ant-picker-date-panel {
  width: 68% !important;
}
.ant-picker-dropdown:not(.full-screen-time-picker) .ant-picker-datetime-panel .ant-picker-date-panel {
  width: 100vw !important;
}

.ant-picker-time-panel-column .ant-picker-time-panel-cell-inner { 
  padding-left: auto !important;

}


.full-screen-time-picker-range .ant-picker-header {
  width: 100vw !important;
}
.full-screen-time-picker-range .ant-picker-content{
  width: 99vw !important;
  /* margin-left: 4%; */
  margin-trim: all;
}

.full-screen-time-picker-range .ant-picker-body .ant-picker-content{
  width: 90vw !important;
}
/* .full-screen-time-picker-range .ant-picker-panels {
  width: 100vw !important;
}  */

/* .full-screen-time-picker-range .ant-picker-body .ant-picker-content {
  width: 98vw  !important;
 } */

.ant-picker-dropdown-range{

  padding: 0px !important;
}
.ant-picker-dropdown-range .ant-picker-ok{
  margin:auto !important;
}
 .ant-picker-dropdown-range .ant-picker-ok .ant-btn{
  width: 100px !important;
  color: #000 !important;
  border-color: #FFF200 !important;
  height: 30px;
  background-color: #FFF200 !important;
  margin-bottom: 10px;
  margin-right: 120px;
}

.text-justify{
text-align: justify !important;
}


body::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

body::-webkit-scrollbar-button:start:decrement,
body::-webkit-scrollbar-button:end:increment {
  display: block;
  height: 0;
  background-color: transparent;
}

body::-webkit-scrollbar-track-piece {
  background-color: #ffffff;
  opacity: 0.2;

  /* Here */
  display: none;

  -webkit-border-radius: 0;
  -webkit-border-bottom-right-radius: 14px;
  -webkit-border-bottom-left-radius: 14px;
}

body::-webkit-scrollbar-thumb:vertical {
  height: 50px;
  background-color: #333333;
  -webkit-border-radius: 8px;
}

.swal2-container {
  max-height: 100vh;
  overflow-y: auto !important;
  z-index: 100001
}









.bronze{
  background: linear-gradient(45deg,  rgba(223,182,103,1) 0%,rgba(249,243,232,1) 56%,rgba(231,192,116,1) 96%);
  opacity: 86%;
}
  




.silver{
  background: linear-gradient(45deg,  rgba(160,160,160,1) 0%,rgba(232,232,232,1) 56%);
  opacity: 94%;
  
}






.gold{
  background: linear-gradient(45deg,  rgba(222, 198, 16, 0.905) 0%,rgb(244, 244, 207) 56%,rgb(222, 208, 18) 96%);
  opacity: 90%;
}

.blur-card {
  backdrop-filter: blur(2px);
}


.bronze-p{  color: #dfb667;}
.silver-p{  color: #a0a0a0;}
.gold-p{  color: #e1c50b;}


.custom-multiple-images-input .ant-upload-list-item-container {
  width: 100%!important;
  margin: 0!important;
}

.custom-multiple-images-input .ant-upload-select {
  width: 100%!important;
}

.custom-multiple-images-input .ant-upload-list {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1rem;
}

.custom-multiple-images-input .ant-upload-list-picture-card::before {
  display: none!important;
}

.custom-multiple-images-input .ant-upload-list-item::before {
  display: none!important;
}
.custom-document-input {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1rem;
  padding-bottom: 0.5rem
}

.custom-document-input .ant-upload-list-item-container {
  width: 100%!important;
  margin: 0!important;
}

.custom-document-input .ant-upload-select {
  width: 100%!important;
}

.custom-document-input .ant-upload-list {
  display: grid;
  grid-template-columns: 100%;
  gap: 1rem;
}

.custom-document-input .ant-upload-list-picture-card::before {
  display: none;
}

.form-hidden {
  height: 0;
  width: 0;
  position: absolute;
  left: -1500px;
}


/*.ant-image-preview-operations:not(:first-child) {*/
/*  display: none;*/
/*}*/

.ant-image-preview-wrap{
  position: absolute;
  background-color: #1e1e2d;
}

.bso-svg {
  fill: currentColor;
}

.bso-svg > path {
  stroke: currentColor;
}

#markdown img {
  max-width: 100%;
}

.markdown-container p {
  margin-bottom: 0;
}


.customSizedUpload .ant-upload {
  width: 100% !important;
  height: 100% !important;
  padding-top: .5em;
  padding-bottom: .4em;
}