.Player__controls {
    pointer-events: none;
    opacity: 0;
    background: #00000077;
    transition: opacity 0.2s;
}

.Player__controls-open {
    pointer-events: auto !important;
    opacity: 100% !important;
    background: #00000077;
    transition: opacity 0.2s;
}

.Player__close {
    pointer-events: none;
    opacity: 0;
    background: #00000077;
    transition: opacity 0.2s;
}

.Player__close-open {
    pointer-events: auto !important;
    opacity: 100% !important;
    background: #00000077;
    transition: opacity 0.2s;
}